<template>
  <div class="main">
    <header-t/>
    <nav2/>
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item >课程分类</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="cont">
      <div class="type-wrap">
        <!-- 一级 -->
        <dl class="cation-list">
          <dd>
            <span class="all" :class="{ on: parentTag }" @click="changeParentAll(true)">全部</span>
            <span 
              :class="{ 'default on' : one.id == parentCurrent, 'default': one.id != parentCurrent}" 
              v-for="(one, index) in classLists" 
              :key="one.id" 
              @click="changeParent(index, one.id)" 
              :id="one.id"
            >
              {{one.categoryName}}
            </span>
            <!-- <span class="default on">考前培训</span>
            <span class="default">继续教育培训</span>
            <span class="default">职称继续教育</span>
            <span class="default">模拟考试</span> -->
          </dd>
        </dl>
        <!-- 二级 -->
        <dl class="cation-list">
          <dd>
            <span class="all" :class="{ on: secondTag }"  @click="changeSecondAll(true)">全部</span>
            <span 
              :class="{'default on' : second.id == secondCurrent, 'default': second.id != secondCurrent}" 
              v-for="(second,index) in secondData" 
              :key="second.id" 
              @click="changeSecond(index, second.id)"
            >
              {{second.categoryName}}
            </span>
            <!-- <span class="default on">岗位八大员</span>
            <span class="default">建设工程监理人员</span>
            <span class="default">燃气从业人员</span> -->
          </dd>
        </dl>
        <!-- 三级 -->
        <dl class="cation-list">
          <dd>
            <span class="all" :class="{ on: thirdTag }"  @click="changeThirdTagAll(true)">全部</span>
            <span 
              :class="{'default on' : third.id == thirdCurrent, 'default': third.id != thirdCurrent}" 
              v-for="(third,index) in thirdData" 
              :key="third.id" 
              @click="changeThird(index, third.id)"
            >{{third.categoryName}}</span>
            <!-- <span class="default">市政质量员</span>
            <span class="default">安全员</span> -->
          </dd>
        </dl>
         <!-- 四级 -->
         <dl class="cation-list"  v-if="fourthData.length > 0">
          <dd>
            <span class="all" :class="{ on: fourthTag }"  @click="changeFourthTagAll(true)">全部</span>
            <span 
              :class="{'default on' : fourth.id == fourthCurrent, 'default': fourth.id != fourthCurrent}" 
              v-for="(fourth,index) in fourthData" 
              :key="fourth.id" 
              @click="changeFourth(index, fourth.id)"
            >{{fourth.categoryName}}</span>
            <!-- <span class="default">市政质量员</span>
            <span class="default">安全员</span> -->
          </dd>
        </dl>
      </div>
      <div class="result">
        <ul  v-if="result.length > 0">
          <li @click="goDetail(item.id)" v-for="item in result" :key="item.id">
            <img class="img" 
              :src="item.logo == '' ? defaultImg : item.logo "
              >
              <div class="infobox">
            <p class="tit">{{item.courseName}}</p>
            <div class="info">
              <p  v-if="item.endSigningTime">截止报名日期：{{ item.endSigningTime }}</p>
            </div>
            <div class="btn_wrap">
              <div class="price">
                <p v-if="item.price"><span style="font-size: 14px;">¥ </span>{{item.price}}</p>
                <p v-if="!item.price&&item.needUploadEndowments==1">免费</p>
              </div>
            <el-button round class="btn" @click="goDetail(item.id)">课程详情</el-button>
            </div>
          </div>
          </li>
        </ul>
        <div v-else class="noDataTip">没有查到相关的课程！</div>
        <el-row class="pagination">
          <Pagination
            :total="search.total"
            :page.sync="search.page"
            :limit.sync="search.size"
            @pagination="getProductData"
          />
        </el-row>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
import { getClass, getCourseList } from '@/api/product.js'
import Pagination from '@/components/Pagination'
export default {
  name: 'Classroom',
  components: {
    HeaderT,
    Nav2,
    Footer,
    Pagination
  },
  data(){
    return {
      defaultImg: 'https://qiniu-cdn6.jinxidao.com/uploads/active/images/loading-238x184.png',
      parentTag: true, // 一级父类
      secondTag: true, // 
      thirdTag: true,
      fourthTag: true,
      parentCurrent:0,
      secondCurrent:null,
      thirdCurrent:null,
      fourthCurrent:null,
      secondData: [],
      thirdData: [], //三级数据源
      fourthData: [], //四级数据源
      parentId: null, //一级分类id,
      secondId: null, // 二级分类id,
      thirdId: null, //  三级分类id
      fourthId: null, //  四级分类id
      classLists:[],//总数据
      // 搜索条件
      search: {
        page: 1,
        size: 40,
        total: 1
        
      },
      total: 0,
      result: []//列表搜索结果
    }
  },
  created() {
    console.log('created....',this.$route.query);
    // console.log(this.$route.query);
    this.parentId = this.$route.query.parent
    this.parentCurrent = this.$route.query.parent
    this.secondId = this.$route.query.second
    this.secondCurrent = this.$route.query.second
    this.thirdId = this.$route.query.three
    this.thirdCurrent = this.$route.query.three
    if(this.parentId) {
      this.parentCurrent = this.parentId
    }
  },
  async mounted() {
    await this.getClassData();
    await this.init()
    
    
  },
  methods:{
    // 初始化数据
    init() {
      if(this.parentId && this.secondId == undefined){

      console.log('init start...',this.parentId,this.secondId);
        this.changeParent(null,this.parentId )
        return
      }else if(this.parentId && this.secondId) {
        console.log('一二级id不为空');

        this.classLists.forEach(item=>{
          if(item.id == this.parentId) {
            this.parentTag = false // 取消“全部”的选中状态
            this.parentCurrent = item.id
            this.secondData = item.rows
            this.fourthCurrent = -1
            console.log('secondData:',this.secondData);
          }
        })

        this.secondData.forEach(item=>{
          if(item.id == this.secondId) {
            this.secondTag = false // 取消“全部”的选中状态
            this.secondCurrent = item.id
            this.thirdData = item.rows
            this.fourthCurrent = -1
            console.log('thirdData:', this.thirdData);
            
          }
        })
        if(this.thirdId) {
          console.log('三级不为空');
          this.thirdData.forEach(item=>{
            if(item.id == this.thirdId) {
              this.thirdTag = false // 取消“全部”的选中状态
              this.thirdCurrent = item.id
              this.thirdId = item.id
              this.fourthData = item.rows||[]
              this.fourthCurrent = -1
            }
          })
        }
      }else if(this.parentId && !this.secondId){
        console.log('不知道为什么会有这个判断，看不懂')
        this.thirdId = this.parentId
        if(this.$route.query.recommend == 1) {
          this.parentTag = false // 取消“全部”的选中状态
          // this.parentCurrent = this.classLists[0].id
          if(this.$route.query.parent == 1) {
            this.secondData = this.classLists[0].rows
          }
          if(this.$route.query.parent == 2) {
            this.secondData = this.classLists[1].rows
          }
          if(this.$route.query.parent == 6) {
            this.secondData = this.classLists[2].rows
          }
        }
      }
      this.getProductData()
    },
    //获取课程接口数据
    getClassData(){
      return new Promise((resolve, reject) => {
        getClass().then(res=>{
          if(res.status == 200) {
            this.classLists = res.data.data.rows
            console.log('classLists:', this.classLists);
            resolve()
          }else{
            reject()
          }
        })
      })
    },
    // 跳转详细页面
    goDetail(id) {
      this.$router.push({
        path: '/classdetail',
        query: {
          id,
        }
      })
    },
    // 一级分类all 点击
    changeParentAll(val) {
      this.parentTag = val
      this.parentCurrent = 0
      this.categoryId = 0
      this.secondTag = true //二级分类变回全部选中状态
      this.secondCurrent = 0 //二级分类变回全部选中状态

      this.thirdTag = true //三级分类变回全部选中状态
      this.thirdCurrent = 0 //三级分类变回全部选中状态
      this.thirdData = [] // 清空三级分类数据
      // search
      this.thirdId = 0
      this.getProductData()
    },
    //二级分类all 点击
    changeSecondAll(val) {
      this.secondTag = val
      this.secondCurrent = 0
      this.thirdData = [] // 清空三级分类数据
      
      this.thirdTag = true //三级分类变回全部选中状态
      this.thirdCurrent = 0 //三级分类变回全部选中状态

      // search
      this.thirdId = this.parentId // 传一级的id
      this.getProductData()
    },
    //三级分类all 点击
    changeThirdTagAll(val) {
      this.thirdTag = val
      this.thirdCurrent = 0

      // search
      this.thirdId = this.secondId // 传二级的id
      this.getProductData()
    },
    //四级
    changeFourthTagAll(val) {
      this.fourthTag = val
      this.fourthCurrent = 0

      // search
      this.fourthId = this.thirdId // 传三级的id
      this.getProductData()
    },
    // 一级分类点击
    changeParent(index,id) {
      // console.log(index, id);
      this.parentTag = false
      this.parentCurrent = id
      // this.categoryId = id
      this.secondCurrent = -1 // 重置二级分类tag
      this.secondTag = true // 重置二级分类tag
      this.thirdTag = true // 重置三级分类tag
      this.thirdCurrent = -1 // 重置三级分类tag
      this.fourthTag = true // 重置4级分类tag
      this.fourthCurrent = -1 // 重置4级分类tag
      this.thirdData = [] // 重置数据
      this.fourthData = [] // 重置数据
      console.log(index,id,'changeParentchangeParentchangeParentchangeParent');
      console.log(  this.classLists)
      this.classLists.forEach(item=>{
        if(item.id == id) {
          this.secondData = item.rows
          console.log('secondData:', this.secondData);
        }
      })

      // search
      this.thirdId = id
      this.fourthId = id
      this.getProductData()
    },
    // 二级分类点击
    changeSecond(index,id) {
      this.secondTag = false
      this.secondCurrent = id
      this.thirdCurrent = -1 // 重置三级分类tag
      this.thirdTag = true //三级分类变回全部选中状态
      this.fourthTag = true // 重置4级分类tag
      this.fourthCurrent = -1 // 重置4级分类tag
      this.fourthData = [] // 重置数据
      console.log(index,id);
      this.secondData.forEach(item=>{
        if(item.id == id) {
          this.thirdData = item.rows
          console.log('thirdData:', this.thirdData);
        }
      })

      // search
      this.thirdId = id
      this.fourthId = id
      this.getProductData()
    },
    // 三级分类点击
    changeThird(index,id) {
      this.thirdTag = false
      this.thirdCurrent = id
      this.fourthTag = true // 重置4级分类tag
      this.fourthCurrent = -1 // 重置4级分类tag
      this.thirdData.forEach(item=>{
        if(item.id == id) {
          this.fourthData = item.rows?item.rows:[]
        }
      })
      this.fourthId = id
      this.getProductData()
    },
    //四级
    changeFourth(index,id) {
      this.fourthTag = false
      this.fourthCurrent = id
      console.log(index,id);

      this.getProductData()
    },
    // 统一调用商品搜索接口
    getProductData() {
      console.log('getProductData...',this.thirdId,this.secondId)
      console.log('----------parentId', this.parentCurrent);
      console.log('----------secondId', this.secondCurrent);
      console.log('----------thirdId', this.thirdCurrent);
      console.log('----------fourthId', this.fourthCurrent);
      console.log('++++++++++categoryId', this.categoryId);

      let tmpCategoryId = null
      if(undefined !== this.fourthCurrent && this.fourthCurrent !== -1 && this.fourthCurrent !== 0){
        console.log(4)
        tmpCategoryId = this.fourthCurrent
      }
      else if(undefined !== this.thirdCurrent && this.thirdCurrent !== -1 && this.thirdCurrent !== 0) {
        tmpCategoryId = this.thirdCurrent
        console.log(3)
      }else if(undefined !== this.secondCurrent && this.secondCurrent !== -1 && this.secondCurrent !== 0) {
        tmpCategoryId = this.secondCurrent
        console.log(2)
      }else if(undefined !== this.parentCurrent && this.parentCurrent !== -1) {
        console.log(1)
        tmpCategoryId = this.parentCurrent
      }else {
        tmpCategoryId = 0
      }
      console.log('tmpCategoryId:', tmpCategoryId);
      const params = Object.assign(this.search, {
        categoryId: tmpCategoryId,
        /* firstId: this.parentId,
        secondId: this.secondId,
        thirdId: this.thirdId */
      })
      console.log(params,'params')
      // console.log('---------------------line:331, categoryId:', params.categoryId);
      getCourseList(params).then(res=>{
        console.log(res);
        if(res.status == 200){
          if(res.data.code == 0) {
            const { data } = res.data
            this.result = []
            this.result = data.rows
            this.search.total = data.total
            /* if (this.search.page > Math.ceil(data.total / this.search.size)) {
              this.search.page = Math.ceil(data.total / this.search.size)
            } */
            console.log("this.result.length",this.result.length);
          }
        }else{
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })

    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main{
  height: 100%;
  background-color: #f5f5f5;

  background: url('../../assets/play-bg.jpeg') left top repeat-y #fff !important;
  .location{
    margin: 20px auto 0;
    // border:1px #f00 solid;
    width: 1170px;
    text-align: left;
  }
  .cont{
    margin: 16px auto 20px;
    width: 1170px;
    .type-wrap{
      background-color: #fff;
      padding: 10px;
      .cation-list {
          overflow: hidden;
          border-bottom: 1px dashed #d2d2d2;
          dt{
            float: left;
            width: 64px;
            font-weight: 700;
            line-height: 48px;
            position: relative;
            color: #333
          }
          dt:after {
            content: "";
            position: absolute;
            right: 12px;
            top: 22px;
            border: 1px solid #333;
            border-width: 0 1px 1px 0;
            width: 4px;
            height: 4px;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            transform: rotate(-45deg)
        }
        dd{
          overflow: hidden;
          span{
            color: #8d8d91;
            font-size: 14px;
            height: 24px;
            float: left;
            padding: 0 10px;
            line-height: 24px;
            border-radius: 4px;
            margin: 7px;
            background: transparent
          }
          .on,:hover{
            color: #fff;
            background-color: #0065b3;
            cursor: pointer;

          }
          :last-child {
            border-right: 0 none
          }
        }
      }
      :last-of-type dd {
        border-bottom: 0 none
      }
    }
    .result{
      margin-top: 20px;
      // background-color: #f5f5f5;
      ul{
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        li {
          width: 270px;
          background-color: #fff;
          padding: 0px;
          margin-bottom: 20px;
          box-sizing: border-box;
          margin-right: 30px;
          border-radius: 4px;
          transition: all 0.2s ease-in;
          position: relative;
          top: 0;
          border: 1px solid #eee;
          .img {
            width: 100%;
            height: 178px;
            overflow: hidden;

          }
          .infobox{
            padding: 5px 0 10px;
          }
          .tit{
            font-size: 16px;
            color:#333;
            text-align: left;
            margin-top: 4px;
            padding: 0 10px;
            font-weight: bold;
          }
          .info {
            color: #666;
            text-align: left;
            line-height: 23px;
            height: 23px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            margin: 5px 0;
            padding: 0 10px;
          }
          .btn_wrap{
            display: flex;
            align-items: center;
            justify-content:space-between;
            padding: 0 15px;
            .price{
              width: 50%;
              text-align: left;
              // text-indent: 20px;
              font-size: 20px;
              font-weight: bold;
              color:#626263;
            }
            .btn{
              border-radius:30px;
              padding: 6px 12px;
              font-size: 12px;

            }
          }
          .w100 {
            width: 100%;
          }
        }
        li:nth-of-type(4n+4) {
          margin: 0 0px 20px 0;
        }
        li:hover {
          top:-5px;
          box-shadow: 0px 10px 9px 0 #ddd;
          cursor: pointer;
          .btn{
            background: #084590;
            color: #fff;
          }
        }
      }
      .pagination {
        margin-top: 30px;
        text-align: center;
        .pagination-container{
          // background-color: #f5f5f5;
        }
      }
      .noDataTip{
        color:#666
      }
    }
  }
}
</style>